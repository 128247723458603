@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@layer {
  ul,
  li {
    list-style: inherit;
    list-style-type: inherit;
  }
}

ul li {
  list-style-type: disc;
  list-style-position: inside;
}

:root {
  --color-primary: #50a199;
  --color-secondary: #f5f5f5;
}

* {
  scroll-behavior: smooth;
}

.wide {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
}

body {
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
  transition: all 0.2s ease;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--color-primary);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px var(--color-primary);
  background-color: #50a1997e;
}

.posts-sidebar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.posts-sidebar::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.posts-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0px var(--color-primary);
  background-color: #50a1997e;
}

a {
  color: var(--color-primary);
}

input,
select {
  outline: none;
}

iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button.ant-btn-primary {
  background-color: var(--color-primary);
  box-shadow: none;
}

.ant-form-item-explain-error {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 4px;
  font-size: 13px;
}

.ant-card-meta-title {
  margin-bottom: 4px !important;
}

/* Swiper CSS */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 14px;
  background: #fff;
  /* border-radius: 15px !important; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

.swiper-slide-thumb-active {
  border: 1px solid var(--color-primary);
}

/* ANTD Design */

.ant-card-meta-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ant-table table {
  font-size: 14px;
}

.ant-table-row-selected .ant-table-cell {
  background-color: #f1f7e9a0 !important;
}

.cart-input input {
  text-align: center !important;
}

.ant-menu-submenu {
  list-style-type: none;
}

/* React Quill */
.quill .ql-toolbar {
  background-color: #f5f5f5;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
}

.quill .ql-container {
  border-radius: 0 0 5px 5px;
}

.quill .ql-container .ql-editor {
  min-height: 100px;
  font-family: "Inter", "sans-serif";
  font-size: 14px;
}

.quill .ql-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}

.quill a {
  color: green;
}

.ql-align-left {
  text-align: left;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.blot-formatter__toolbar-button {
  display: inline-flex !important;
  top: 0;
}
